@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.result-panel {
  overflow: hidden;
}
.last {
  background-color: rgb(46, 95, 201) !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.important-flex{
  display: flex !important;
}

.homeborder {
  border-radius: 38.5px;
}

.s1 {
  color: #000000;
}
